import styled from "@emotion/styled";
import React from "react";
import { Body1, H4, H5 } from "../../../../layouts/TextStyles";
import Card from "./Card";

function Cards() {
  return (
    <Wrapper>
      <Card bg="#E6E2FF" color="#8C40EC" title="Общий балл" score="24">
        Exam score <H4>116</H4>
      </Card>
      <Card bg="#D2F4FF" color="#34A5CF" title="Результат (%)" score="24%">
        Exam score <H4>82%</H4>
      </Card>
      <Card bg="#D5FEC7" color="#5EB03D" title="Правильных" score="24">
        Correct <H4>116</H4>
      </Card>
      <Card bg="#FFEFDA" color="#F29F62" title="Неправильных" score="24"></Card>
    </Wrapper>
  );
}

export default Cards;

const Wrapper = styled.div`
  grid-area: cards;
  display: grid;
  grid-template-columns: repeat(4, auto);
  /* justify-content: space-between; */

  gap: 24px;
  color: grey;
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, calc(50% - 8px));
    gap: 16px;
  }
`;

import styled from "@emotion/styled";
import React from "react";
import { H6 } from "../../../../layouts/TextStyles";

import SubjectResultItem from "./SubjectResultItem";

const Data = [
  { subject: "Mathematical literacy", result: 8, total: 20 },
  { subject: "Reading literacy", result: 11, total: 20 },
  { subject: "History of Kazakhstan", result: 12, total: 20 },
  { subject: "Mathematics", result: 32, total: 40 },
  { subject: "Physics", result: 35, total: 40 },
];

function SubjectResults() {
  return (
    <Wrapper>
      <H6 style={{ position: "sticky" }}>Результаты по предметам</H6>
      {Data.map((d) => (
        <SubjectResultItem item={d.subject} value={d.result} total={d.total} />
      ))}
    </Wrapper>
  );
}

export default SubjectResults;

const Wrapper = styled.div`
  grid-area: subjectResults;
  background: ${(props) => (props.bg ? props.bg : "#fff")};
  border-radius: 16px;
  padding: 24px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  gap: 8px;
`;

import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "../context/Context";
import MainLayout from "../layouts/MainLayout";
import storage from "../services/storageService";

const PrivateRouter = ({ component: Component, fullScreen, ...rest }) => {
  const { user } = useContext(AppContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        user?.isAuthenticated || !!storage?.get("token") ? (
          fullScreen ? (
            <Component {...props} />
          ) : (
            <MainLayout>
              <Component {...props} />
            </MainLayout>
          )
        ) : (
          // <LandingPage signinOpen={signinOpen}  setSigninOpen={setSigninOpen}/>
          <Redirect
            to={{
              pathname: "/sign-in",
            }}
          />
        )
      }
    />
  );
};

export default PrivateRouter;

/*
render={props => (localStorage.getItem("x-auth-token") && auth) ?
*/
//  <Redirect
//    to={{
//      pathname: "/",
//      state: { from: props?.location },
//    }}
//  />;

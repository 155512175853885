import styled from "@emotion/styled";
import React, { useState } from "react";
import { H5 } from "../../../../layouts/TextStyles";
import ArrowRegular from "../../../../assets/icons/ArrowForward.svg";
import Add from "../../../../assets/icons/Add.svg";
import Close from "../../../../assets/icons/Close.svg";
import Pill from "./Pill";
import { useIntl } from "react-intl";
// import ArrowRegular from "../../../../assets/img/landing/arrow-forward.svg";

function Filters({ params, filters, handleRemove }) {
  const { formatMessage, locale } = useIntl();
  //   const data = [
  //   {
  //       region: "Ұлытау обл",
  //       cities: ["Жезқазған", 'Сатпаев'],
  //       schools: [{school: "Жезқазған БИЛ", ''}, "134", "125"],

  //   }
  //   ]

  // const [filters, setFilters] = useState(initial);
  return (
    <Wrapper>
      <H5>{formatMessage({ id: "examAnalysis" })}</H5>
      <Container>
        {filters.map(
          (i, index) =>
            i.selected && (
              <div key={index}>
                <Pill
                  title={i.name}
                  values={i.values}
                  selected={i.selected}
                  // filters={filters}
                  // setFilters={setFilters}
                  id={i.id}
                  index={index}
                  handleRemove={handleRemove}
                />
              </div>
            )
        )}
      </Container>
    </Wrapper>
  );
}

export default Filters;

const Wrapper = styled.div`
  /* background-color: #fff; */
  /* padding: 24px; */
  margin-bottom: 20px;
  border-radius: 8px;
  display: grid;
  gap: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

import axios from "axios";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import {
  loading,
  setLoading,
  REACT_APP_IAM_SERVICE_BASE_URL,
} from "../utils/constants";
import storage from "./storageService";

const history_browser = require("history").createBrowserHistory();
const isLandingPagePath =
  window.location.pathname === "/" || window.location.pathname === "/ru";

export const fetchData = async (method, path, data, cancelToken) => {
  const token = storage?.get("token");
  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (!cancelToken) header.Authorization = "Bearer " + token;

  var response = "";

  try {
    if (method === "get" || method === "delete") {
      setLoading(true);

      response = await axios[method](
        `${path}`,
        { headers: header },
        { params: data }
      );
    } else {
      if (!loading) {
        setLoading(true);
      }

      response = await axios[method](`${path}`, data, { headers: header });
    }

    setLoading(false);

    return response;
  } catch (err) {
    if (err?.response?.status === 401) {
      var refreshTokenResponse = "";
      var resendResponse = "";

      try {
        refreshTokenResponse = await axios["post"](
          `${REACT_APP_IAM_SERVICE_BASE_URL}/auth/refresh-token`,
          { refresh_token: storage?.get("refreshToken") }
        );

        if (
          refreshTokenResponse?.status === 200 ||
          refreshTokenResponse?.status === 201
        ) {
          setUser(
            refreshTokenResponse.data.data.token,
            refreshTokenResponse.data.data.refresh_token
          );
          storage?.remove("redirectAddress");

          if (!cancelToken) {
            header.Authorization = "Bearer " + storage.get("token");
          }

          if (method === "get" || method === "delete") {
            resendResponse = await axios[method](
              `${path}`,
              { headers: header },
              { params: data }
            );
          } else {
            if (!loading) {
              setLoading(true);

              resendResponse = await axios[method](`${path}`, data, {
                headers: header,
              });
            }
          }

          setLoading(false);

          return resendResponse;
        }
      } catch (refreshTokenError) {
        if (refreshTokenError.response.status === 401) {
          const url = new URL(window.location.href);
          const searchParams = new URLSearchParams(url.search);
          const redirectAddress =
            window.location.pathname + window.location.search;
          const user = storage?.get("user");

          if (!isLandingPagePath) {
            if (user?.roles?.length) {
              storage?.set("role", user?.roles[0]);
            }
            if (!searchParams.has("redirectAddress")) {
              searchParams.set("redirectAddress", redirectAddress);

              // if (user?.roles?.length) {
              //   searchParams.set("role", user?.roles[0]);
              // }
            }
            storage?.set("redirectAddress", redirectAddress);
            
            removeUser();

            url.pathname = "/sign-in";
            url.search = searchParams.toString();
            window.location.href = url.href;
          }
        }
      }
    }
    err?.response?.data?.errors?.forEach((err) => {
      if (
        err.code == 1003 &&
        (!storage?.get("redirectAddress") || isLandingPagePath)
      ) {
        history_browser.push("/sign-in");
        removeUser();
        window.location.reload();
      }
      // temporary for SPT, delete just if, not statement
      if (err.code !== 1057 && err.code !== 1067) {
        toast.error(
          err.code ? (
            <FormattedMessage
              id={err.code}
              defaultMessage={err.message}
            ></FormattedMessage>
          ) : (
            err.message
          )
        );
      }
    });
    setLoading(false);
    return err.response;
  }
};

const removeUser = () => {
  storage?.remove("token");
  storage?.remove("user");
  storage?.remove("profile");
};

const setUser = (token, refreshToken) => {
  storage?.set("token", token);
  storage?.set("refreshToken", refreshToken);
};

import styled from "@emotion/styled";
import React from "react";

import Calendar from "../../../assets/icons/Calendar.svg";
import ArrowRegular from "../../../assets/icons/ArrowRegular.svg";
import Timer from "../../../assets/icons/Timer.svg";
import Questions from "../../../assets/icons/Questions.svg";
import Rectangles from "../../../assets/icons/Rectangles.svg";
function ExamCard() {
  return (
    <Wrapper>
      <TitleWrapper>
        <h2 style={{ margin: 0 }}>SPT</h2>
        <Pill>National</Pill>
      </TitleWrapper>
      <Divider />
      <InfoWrapper>
        <InfoContainer>
          <InfoCalendar>
            <Info>
              <Img src={Calendar} />
              21 Oct, 13:00
            </Info>
            <Img src={ArrowRegular} />
          </InfoCalendar>
          <Info>
            <Img src={Questions} />
            100 Вопросов
          </Info>
          <Info>
            <Img src={Rectangles} />
            2/3 Попыток
          </Info>
        </InfoContainer>
        <div>
          <InfoContainer>
            <Info>
              <Img src={Calendar} />
              21 Oct, 13:00
            </Info>

            <Info>
              <Img src={Timer} />
              140 минут
            </Info>
          </InfoContainer>
        </div>
      </InfoWrapper>
      <Button>Начать</Button>
    </Wrapper>
  );
}

export default ExamCard;

const Wrapper = styled.div`
  max-width: 346px;
  min-width: 300px;
  max-height: 230px;
  background: white;
  /* box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.12); */
  box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.12);
  /* box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.12); */
  border-radius: 16px;
  padding: 24px;

  /* display: grid;
  grid-template-columns: auto;
  gap: 20px;

  align-items: flex-start; */
  @media screen and (max-width: 500px) {
    margin: auto;
  }
  @media screen and (max-width: 340px) {
    padding: 16px;
  }
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 20px;
`;

const Pill = styled.div`
  font-size: 10;
  /* background-color: #c2f19d; */
  background-color: #f3f4f4;
  color: darkblue;
  border-radius: 16px;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

const Divider = styled.div`
  background: #bdbdbd;
  width: 100%;
  height: 1px;
  margin: 16px 0;
`;

const InfoWrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, auto);
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 16px auto;
  justify-content: flex-start;
  /* align-content: flex-start; */
  gap: 8px;
  color: #424242;
  font-size: 14px;
  align-items: center;
`;

const Button = styled.button`
  background: #3ccabc;
  background: #5468a4;
  border-radius: 8px;
  padding: 8px 16px;
  display: grid;
  align-items: center;
  outline: none;
  border: none;
  color: white;
  font-family: "Euclid Circular B";
  font-weight: 600;
  margin-top: 16px;
  :hover {
    background-color: #00988c;
    background-color: #3750ac;
  }
  transition: all 0.1s ease-out;
`;

const Img = styled.img``;

const InfoCalendar = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  /* gap: 12.9px; */
`;

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 8px;
`;

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import styled from "@emotion/styled";
import { H5 } from "../../../../layouts/TextStyles";
import Filters from "./Filters";
import DividerH from "../../../../components/DividerH";
import AnalysisType from "./AnalysisType";
import { useIntl } from "react-intl";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function TopChart({
  types,
  filters,
  regions,
  setParams,
  params,
  handleRemove,
  top10,
}) {
  const { locale } = useIntl();

  function formatLabel(str, maxwidth) {
    var sections = [];
    var words = str.split(" ");
    var temp = "";

    words.forEach(function (item, index) {
      if (temp.length > 0) {
        var concat = temp + " " + item;

        if (concat.length > maxwidth) {
          sections.push(temp);
          temp = "";
        } else {
          if (index == words.length - 1) {
            sections.push(concat);
            return;
          } else {
            temp = concat;
            return;
          }
        }
      }

      if (index == words.length - 1) {
        sections.push(item);
        return;
      }

      if (item.length < maxwidth) {
        temp = item;
      } else {
        sections.push(item);
      }
    });

    return sections;
  }

  const labels = top10.map(
    (i) =>
      (i[`title_${locale}`] && formatLabel(i[`title_${locale}`], 50)) ||
      i.full_name
  );

  const options = {
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        position: "top",
        grid: {
          display: true,
        },
        steps: 10,
        stepValue: 5,
        max: 140,
        ticks: {
          stepSize: 10,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "right",
      },
      title: {
        display: false,
        text: "Топ-10",
      },
    },
  };
  const data = {
    labels: labels,
    datasets: [
      {
        //   categoryPercentage: 0.4,
        //   barPercentage: 0.4,
        //   barThickness: 32,

        label: "",
        backgroundColor: "#B0E3FF",
        borderColor: "#B0E3FF",

        data: top10.map((i) => i.average || i.point),
      },
    ],
  };

  return (
    <Wrapper>
      <Filters
        regions={regions}
        types={types}
        params={params}
        setParams={setParams}
        filters={filters}
        handleRemove={handleRemove}
      />
      <DividerH />
      <Container>
        <H5>Топ-10</H5>
        <AnalysisType types={types} params={params} setParams={setParams} />
      </Container>
      <div>
        <Bar options={options} data={data} height="400px" />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 20px 0px;
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  /* height: 600px; */
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
`;

import styled from "@emotion/styled";
import React from "react";
import DividerH from "../../../components/DividerH";
import Kazakh from "../../../assets/img/kazakhstan.png";
import Russian from "../../../assets/img/russia.png";

import { Subjects } from "../data";
import Option from "./Option";

function ExamSetup() {
  return (
    <Wrapper>
      ExamSetup
      <Card>
        <MenuItem>
          <Title>Выберите язык экзамена</Title>
          <Options>
            <Option icon={<img src={Kazakh} />} title="Қазақша" />
            <Option icon={<img src={Russian} />} title=" Русский" />
          </Options>
        </MenuItem>
        <DividerH />
        <MenuItem>
          <Title>Выберите этап</Title>
          <Options>
            <Option icon={<Number>1</Number>} title="Первый этап"></Option>
            <Option icon={<Number>2</Number>} title="Второй этап"></Option>
          </Options>
        </MenuItem>
        <DividerH />
        <MenuItem>
          <Title>Выберите направление</Title>
          <Options>
            <Option title="Естественные науки" />
            <Option title="Гуманитарные науки" />
          </Options>
        </MenuItem>
        <DividerH />
        <MenuItem>
          <Title>Обязательные предметы</Title>
          <Options>
            {Subjects.map(
              (subject) =>
                !subject.elective && (
                  <Option
                    // icon={<img src={subject.icon} style={{ height: 30 }} />}
                    icon={subject.icon}
                    icon2={subject.icon2}
                    title={subject.name}
                  />
                )
            )}
          </Options>
        </MenuItem>
        <DividerH />
        <MenuItem>
          <Title>Выберите профильные предметы</Title>
          <Options>
            {Subjects.map(
              (subject) =>
                subject.elective && (
                  <Option
                    // icon={<img src={subject.icon} style={{ height: 30 }} />}
                    // icon={subject.icon}
                    icon={subject.icon}
                    icon2={subject.icon2}
                    title={subject.name}
                  />
                )
            )}
          </Options>
        </MenuItem>
      </Card>
    </Wrapper>
  );
}

export default ExamSetup;

const Wrapper = styled.div`
  /* padding: 24px; */
  margin: auto;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const Card = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  margin: auto 20px;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  /* gap: 20px; */
  @media screen and (max-width: 500px) {
    margin: 16px;
    gap: 20px;
    padding: 16px;
  }
`;

const MenuItem = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  /* flex-direction: column; */
  gap: 16px;
  @media screen and (max-width: 500px) {
    grid-template-columns: auto;
  }
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  cursor: pointer;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: #1d1e1c;
`;

const Number = styled.div`
  background-color: #eeeeee;
  padding: 4px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: grid;
  justify-content: center;
  align-items: center;
`;

import React, { useContext, useLayoutEffect, useState } from "react";
import styled from "@emotion/styled";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import {
  REACT_APP_IAM_SERVICE_BASE_URL,
} from "../utils/constants";

import { fetchData } from "../services/fetchData";
import { AppContext } from "../context/Context";
import MobileSidebar from "../components/sidebar/mobileSidebar";

export default function MainLayout({ children }) {
  const [isMenu, setIsMenu] = useState(false);
  const [isMenuMobile, setIsMenuMobile] = useState(false);
  const { user, isSideBarOpen, setSideBarOpen } = useContext(AppContext);
  
  // const [isSideBarOpen, setSideBarOpen] = useState(false);
  const handleDrawer = () => {
    setSideBarOpen(!isSideBarOpen);
    if (isMenu == true) {
      setIsMenu(false);
    }
  };

  const handleDrawerMobile = () => {
    setIsMenuMobile(!isMenuMobile);
  };

  const handleDrawerClose = () => {
    setSideBarOpen(false);
    setIsMenuMobile(false);
  };
  

  

  // const { user, isSideBarOpen, setSideBarOpen } = useContext(AppContext);

  // const handleDrawerOpen = () => {
  //   setSideBarOpen(true);
  // };

  // const handleSideBarMouseOver = (bool) => {
  //   setIsMenu(isSideBarOpen || bool);
  // };

  // const handleDrawerClose = () => {
  //   setSideBarOpen(false);
  //   setOpen(false);
  // };

  // return (
  //   <div className={classes.root}>
  //     <Navbar
  //       open={isSideBarOpen || open}
  //       handleDrawerOpen={handleDrawerOpen}
  //       handleDrawerClose={handleDrawerClose}

  return (
    <Wrapper>
      <Sidebar
        isMenu={isSideBarOpen || isMenu}
        setIsMenu={setIsMenu}
        handleDrawer={handleDrawer}
        handleDrawerMobile={handleDrawerMobile}
        handleDrawerClose={handleDrawerClose}
        isMenuMobile={isMenuMobile}
        setIsMenuMobile={setIsMenuMobile}
      />
      <MobileSidebar
        isMenu={isMenuMobile}
        setIsMenu={setIsMenuMobile}
        handleDrawerClose={handleDrawerMobile}
      />
      <Main>
        <Navbar
          isMenu={isMenu || isSideBarOpen}
          handleDrawer={handleDrawer}
          handleDrawerMobile={handleDrawerMobile}
        />
        <Container>{children}</Container>
      </Main>
      
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  background-color: rgba(72, 94, 173, 0.08);

  min-height: 100vh;
`;

const Main = styled.div`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: auto 20px;
  @media screen and (max-width: 500px) {
    margin: auto;
  }
`;

import styled from "@emotion/styled";
import React, { useRef, useState } from "react";
import { ReactComponent as ChevronDown } from "../../../../assets/img/landing/chevron_down.svg";
import useOnClickOutside from "../../../../utils/useClickOutside";
import Tooltip from "./Tooltip";
import { useIntl } from "react-intl";

function AnalysisType({
  types,
  analysisType,
  setAnalysisType,
  params,
  setParams,
}) {
  const [isMenu, setIsMenu] = useState(false);
  const { formatMessage } = useIntl();

  const ref = useRef();
  const tooltipRef = useRef();
  const handler = () => {
    setIsMenu(false);
  };
  const handleClick = (e) => {
    setParams((prev) => ({ ...prev, page: 0, size: 20, analysisType: e }));
  };
  useOnClickOutside(ref, tooltipRef, handler);

  return (
    <Wrapper>
      <TypeSelect onClick={() => setIsMenu(!isMenu)} ref={ref}>
        {formatMessage({ id: params.analysisType })}
        <ChevronDown
          fill="#000"
          style={{
            // transform: isLanguage ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "all 0.2s ease-in-out",
          }}
        />
        <Tooltip
          ref={ref}
          tooltipRef={tooltipRef}
          values={types}
          isMenu={isMenu}
          setIsMenu={setIsMenu}
          handleEvent={handleClick}
          params={params}
        />
      </TypeSelect>
    </Wrapper>
  );
}

export default AnalysisType;

const Wrapper = styled.div`
  position: relative;
`;
const TypeSelect = styled.div`
  background-color: #ededed;
  padding: 8px 16px;
  border-radius: 8px;
  width: 200px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

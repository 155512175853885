import styled from "@emotion/styled";
import React from "react";

import Close from "../../../../assets/icons/Close.svg";

function Pill({
  id,
  title,
  values,
  selected,
  filters,
  setFilters,
  index,
  handleRemove,
}) {
  // const [isMenu, setIsMenu] = useState(false);

  // const ref = useRef();
  // const tooltipRef = useRef();
  // const handler = () => {
  //   setIsMenu(false);
  // };
  // const handleClick = () => {};
  // useOnClickOutside(ref, tooltipRef, handler);
  return (
    <Wrapper selected={selected}>
      <div>{title}</div>
      {id !== "COUNTRY" && (
        <Img
          src={Close}
          selected={selected}
          onClick={() => {
            handleRemove(id);
          }}
        />
      )}
    </Wrapper>
  );
}

export default Pill;

const Wrapper = styled.div`
  background: ${({ selected }) => (selected ? "#b0e3ff" : "#fff")};
  border: ${({ selected }) =>
    selected ? ".5px solid #b0e3ff" : ".5px solid #ccc"};
  border-radius: 40px;
  padding: 8px 16px;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
`;

const Img = styled.img`
  transform: rotate(${({ selected }) => (selected ? "0deg" : "45deg")});
  transition: all 0.2s ease-in-out;
  /* transform: rotate(45deg); */
`;

import styled from "@emotion/styled";
import React from "react";
import { Body1 } from "../../../../layouts/TextStyles";

function SummaryItem({ item, value }) {
  return (
    <Wrapper>
      <Body1 style={{ color: "#637381", minWidth: 140 }}>{item}</Body1>

      <Body1 style={{ fontWeight: "600", color: "#1D1E1C", minWidth: 115 }}>
        {value}
      </Body1>
    </Wrapper>
  );
}

export default SummaryItem;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;

  justify-content: space-between;
`;

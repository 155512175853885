import React, { forwardRef, useContext } from "react";
import styled from "@emotion/styled/macro";
import { useIntl } from "react-intl";

const Tooltip = forwardRef(
  ({ values, isMenu, setIsMenu, handleEvent }, ref) => {
    const { formatMessage } = useIntl();
    return (
      <Select isOpen={isMenu} ref={ref}>
        {values.map((v, i) => (
          <Container key={i}>
            <Option onClick={() => handleEvent(v.name)}>
              {formatMessage({ id: v.name })}
            </Option>
            <DividerH />
          </Container>
        ))}
      </Select>
    );
  }
);

export default Tooltip;

const Select = styled.div`
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(150, 170, 180, 0.15),
    inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(40px);
  border-radius: 6px;
  padding: 8px;
  min-width: 100%;

  position: absolute;
  top: 48px;
  left: 0px;
  /* z-index: 1000; */
  cursor: pointer;
  z-index: 1;
  display: grid;
  gap: 4px;
  /* grid-template-columns: 150px; */
  /* grid-template-columns: 70px; */
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: 0.2s ease-in-out;
  /* display: ${(props) => (props.isOpen ? "block" : "none")}; */
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transform: ${(props) =>
    props.isOpen
      ? "skewY(0deg) rotate(0deg) translateX(0px) translateY(0px)"
      : "skewY(0deg) rotate(0deg) translateX(0px) translateY(-10px)"};

  max-height: 280px;
  overflow: auto;
  @media screen and (max-width: 375px) {
    /* right: 20px; */
  }
`;

const Option = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  justify-content: start;
  align-items: center;
  border-radius: 8px;
  padding: 8px 12px;
  white-space: nowrap;
  :hover {
    background-color: rgba(145, 158, 171, 0.08);
  }
  img {
    border-radius: 8px;
  }
`;

const Img = styled.img`
  border-radius: 50%;

  background-color: #f3f4f5;
  padding: 4px;
`;
const DividerH = styled.div`
  height: 2px;
  width: 100%;
  background: rgba(237, 237, 237, 1); ;
`;

const Container = styled.div`
  :last-child {
    ${DividerH} {
      display: none;
      visibility: none;
    }
  }
`;

// import Math from "../../assets/icons/subjects/Math.svg";
// import Biology from "../../assets/icons/subjects/Biology.svg";
// import Chemistry from "../../assets/icons/subjects/Chemistry.svg";
// import EnglishLanguage from "../../assets/icons/subjects/EnglishLanguage.svg";
// import Geography from "../../assets/icons/subjects/Geography.svg";
// import History from "../../assets/icons/subjects/History.svg";
// import Humanity from "../../assets/icons/subjects/Humanity.svg";
// import KazakhLanguage from "../../assets/icons/subjects/KazakhLanguage.svg";
// import KazakhLit from "../../assets/icons/subjects/KazakhLit.svg";
// import MathLiteracy from "../../assets/icons/subjects/MathLiteracy.svg";
// import Physics from "../../assets/icons/subjects/Physics.svg";
// import Reading from "../../assets/icons/subjects/Reading.svg";
// import RussianLanguage from "../../assets/icons/subjects/RussianLanguage.svg";
// import WorldHistory from "../../assets/icons/subjects/WorldHistory.svg";
// import RussianLit from "../../assets/icons/subjects/RussianLit.svg";
import { ReactComponent as Math } from "../../assets/icons/subjects/Math.svg";
import { ReactComponent as Biology } from "../../assets/icons/subjects/Biology.svg";
import { ReactComponent as Chemistry } from "../../assets/icons/subjects/Chemistry.svg";
import { ReactComponent as EnglishLanguage } from "../../assets/icons/subjects/EnglishLanguage.svg";
import { ReactComponent as Geography } from "../../assets/icons/subjects/Geography.svg";
import { ReactComponent as History } from "../../assets/icons/subjects/History.svg";
import { ReactComponent as Humanity } from "../../assets/icons/subjects/Humanity.svg";
import { ReactComponent as KazakhLanguage } from "../../assets/icons/subjects/KazakhLanguage.svg";
import { ReactComponent as KazakhLit } from "../../assets/icons/subjects/KazakhLit.svg";
import { ReactComponent as MathLiteracy } from "../../assets/icons/subjects/MathLiteracy.svg";
import { ReactComponent as Physics } from "../../assets/icons/subjects/Physics.svg";
import { ReactComponent as Reading } from "../../assets/icons/subjects/Reading.svg";
import { ReactComponent as RussianLanguage } from "../../assets/icons/subjects/RussianLanguage.svg";
import { ReactComponent as WorldHistory } from "../../assets/icons/subjects/WorldHistory.svg";
import { ReactComponent as RussianLit } from "../../assets/icons/subjects/RussianLit.svg";

// blue color 3750ac

export const Subjects = [
  {
    name: "Математика сауаттылығы",
    icon: <MathLiteracy fill="#616161" />,
    icon2: <MathLiteracy fill="#fff" />,
    elective: false,
  },
  {
    name: "Оқу сауаттылығы",
    // icon: Reading,
    icon: <Reading fill="#616161" />,
    icon2: <Reading fill="#fff" />,

    elective: false,
  },
  {
    name: "Қазақстан тарихы",
    icon: <History fill="#616161" />,
    icon2: <History fill="#fff" />,
    // icon: History,
    elective: false,
  },
  {
    name: "Математика",
    icon: <Math fill="#616161" />,
    icon2: <Math fill="#fff" />,
    elective: true,
  },
  {
    name: "Физика",
    icon: <Physics fill="#616161" />,
    icon2: <Physics fill="#fff" />,
    elective: true,
  },
  {
    name: "Химия",
    icon: <Chemistry fill="#616161" />,
    icon2: <Chemistry fill="#fff" />,
    elective: true,
  },
  {
    name: "Биология",
    icon: <Biology fill="#616161" />,
    icon2: <Biology fill="#fff" />,
    elective: true,
  },
  {
    name: "География",
    icon: <Geography fill="#616161" />,
    icon2: <Geography fill="#fff" />,
    elective: true,
  },
  {
    name: "Құқық",
    icon: <Humanity fill="#616161" />,
    icon2: <Humanity fill="#fff" />,
    elective: true,
  },
  {
    name: "Дүние жүзі тарихы",
    icon: <WorldHistory fill="#616161" />,
    icon2: <WorldHistory fill="#fff" />,
    elective: true,
  },
  {
    name: "Қазақ тілі",
    icon: <KazakhLanguage fill="#616161" />,
    icon2: <KazakhLanguage fill="#fff" />,
    elective: true,
  },
  {
    name: "Орыс тілі",
    icon: <RussianLanguage fill="#616161" />,
    icon2: <RussianLanguage fill="#fff" />,
    elective: true,
  },
  {
    name: "Ағылшын тілі",
    icon: <EnglishLanguage fill="#616161" />,
    icon2: <EnglishLanguage fill="#fff" />,
    elective: true,
  },
  {
    name: "Қазақ әдебиеті",
    icon: <KazakhLit fill="#616161" />,
    icon2: <KazakhLit fill="#fff" />,
    elective: true,
  },
  {
    name: "Орыс әдебиеті",
    icon: <RussianLit fill="#616161" />,
    icon2: <RussianLit fill="#fff" />,
    elective: true,
  },
];

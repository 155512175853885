import { useContext } from "react";
import AppRouter from "./router/Router";
import { AppContext } from "./context/Context";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { IntlProvider } from "react-intl";
import messages_kz from "./locales/kz.json";
import messages_ru from "./locales/ru.json";
import messages_en from "./locales/en.json";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const messages = {
  ru: messages_ru,
  kz: messages_kz,
  en: messages_en,
};

function App() {
  const { lang } = useContext(AppContext);

  const theme = createMuiTheme({
    palette: {
      // primary: {
      //   light: "#757ce8",
      //   main: "#5e43c4",
      //   dark: "#002884",
      //   contrastText: "#fff",
      // },
      primary: {
        light: "#1976d2",
        main: "#3750ac",
        dark: "#00287c",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
      gradient: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
        // gg: "linear-gradient(to right bottom, #00C4A1, #4ECCC4 )",
      },
    },
    typography: {
      fontFamily: [
        "Euclid Circular B",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  return (
    <div>
      <IntlProvider messages={messages[lang]} locale={lang} defaultLocale="en">
        <ThemeProvider theme={theme}>
          <AppRouter />
          <ToastContainer
            position="bottom-left"
            // hideProgressBar={true}
            autoClose={5000}
            icon={true}
            // newestOnTop={true}
            // closeOnClick={true}
          />
        </ThemeProvider>
      </IntlProvider>
    </div>
  );
}

export default App;

import styled from "@emotion/styled/macro";
import React, { useEffect, useState } from "react";
import { Body1 } from "../../../../layouts/TextStyles";

function SubjectResultItem({ item, value, total }) {
  //   const width = (value / total) * 100;
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth((value / total) * 100);
  }, []);
  return (
    <Wrapper>
      <Body1 style={{ color: "#637381", minWidth: 200 }}>{item}</Body1>

      <Bar>
        <Progress width={width} />
      </Bar>
      <Container>
        <Body1
          style={{
            fontWeight: "600",
            color: "#1D1E1C",
            width: 80,
          }}
        >
          {value} из {total}
        </Body1>
      </Container>
    </Wrapper>
  );
}

export default SubjectResultItem;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const Bar = styled.div`
  background: #f0f0f0;
  width: 100%;
  height: 6px;
  border-radius: 10px;
  position: relative;
  min-width: 200px;
`;

const Progress = styled.div`
  background: #91f1da;
  width: ${(props) => (props.width ? `${props.width}%` : "0")};
  height: 6px;
  border-radius: 10px;
  transition: all 0.8s ease-in-out;
`;

const Container = styled.div`
  text-align: right;

  @media screen and (max-width: 500px) {
    padding-right: 24px;
  }
`;

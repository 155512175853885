import styled from "@emotion/styled/macro";
import React from "react";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import ExamAnalysis from "./components/examAnalysis/ExamAnalysis";
import ExamCard from "./components/ExamCard";
import ExamSetup from "./components/ExamSetup";
import Results from "./components/results/Results";
import Success from "./components/Success";
// import Navbar from "./components/Navbar";

function CustomPage() {
  const [isMenu, setIsMenu] = React.useState(false);
  return (
    <Wrapper>
      <Sidebar isMenu={isMenu} setIsMenu={setIsMenu} />
      <Main>
        <Navbar isMenu={isMenu} />
        {/* <Results /> */}
        <ExamAnalysis />
        {/* <ExamCard /> */}
      </Main>
    </Wrapper>
  );
}

export default CustomPage;

const Wrapper = styled.div`
  display: flex;
  /* grid-template-columns: 200px auto; */
  background-color: rgba(72, 94, 173, 0.08);
  min-height: 100vh;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
    color: #1d1e1c;
  }
`;

const Main = styled.div`
  /* max-width: 1234px; */
  width: 100%;
`;

const ExamsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(3, auto); */
  /* justify-content: start; */
  align-content: flex-start;
  margin: 24px;
  gap: 20px;
  @media screen and (max-width: 500px) {
    justify-content: center;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-left: 80px;
  @media screen and (max-width: 500px) {
    margin-left: auto;
  }
`;

const Title = styled.h1`
  margin: auto 24px;
  color: #1d1e1c;
  @media screen and (max-width: 500px) {
    /* margin-left: auto; */
    text-align: center;
  }
`;

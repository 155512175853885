import styled from "@emotion/styled";
import React from "react";
import Card from "./Card";
import Cards from "./Cards";
import Overview from "./Overview";
import SubjectResults from "./SubjectResults";
import Summary from "./Summary";

function Results() {
  return (
    <Wrapper>
      <Cards />
      <Summary /> <SubjectResults />
      <Overview />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  margin: 40px 16px;
  margin-bottom: 80px;
  display: grid;
  /* flex-direction: column; */
  /* grid-template-rows: auto; */
  grid-template-columns: auto auto auto auto;
  grid-template-areas:
    "cards cards cards cards"
    "summary subjectResults subjectResults subjectResults"
    "overview overview overview overview";

  /* "subjectResults"; */
  gap: 24px;
  @media screen and (max-width: 500px) {
    grid-template-columns: auto;
    grid-template-areas:
      "summary"
      "cards"
      "subjectResults"
      "overview";
  }
`;
export default Results;

import styled from "@emotion/styled";
import React, { useState } from "react";

function Option({ icon, icon2, title }) {
  const [isSelected, setIsSelected] = useState(false);
  return (
    <Wrapper isSelected={isSelected} onClick={() => setIsSelected(!isSelected)}>
      {isSelected && icon2 ? icon2 : icon}
      <p>{title}</p>
    </Wrapper>
  );
}

export default Option;

const Wrapper = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  /* border: 1px #c4c4c4 solid; */
  border: ${(props) =>
    props.isSelected ? "1px solid #3750ac " : "1px #c4c4c4 solid"};
  background: ${(props) => (props.isSelected ? "#3750ac  " : " #fff ")};

  border-radius: 8px;
  min-height: 42px;

  p {
    color: ${(props) => (props.isSelected ? "white " : "inherit")};
  }

  svg {
    height: 30px;
    width: 30px;
  }
`;

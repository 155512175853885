import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import storage from "./../../../services/storageService";
import { fetchData } from "./../../../services/fetchData";
import { REACT_APP_IAM_SERVICE_BASE_URL } from "../../../utils/constants";
import { AppContext } from "../../../context/Context";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SignInRedirect = () => {
  let query = useQuery();
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(AppContext);
  const history = useHistory();

  const signIn = async () => {
    setLoading(true);
    try {
      const result = await fetchData(
        "post",
        `${REACT_APP_IAM_SERVICE_BASE_URL}/auth/eduway-login`,
        { token: query.get("token") },
        true
      );
      console.log("response", result);
      if (result.status == 200 || result.status == 204) {
          authenticate(result.data.data)
      }
    } catch (error) {
      error?.response?.data?.errors?.forEach((err) => {});
    } finally {
      setLoading(false);
    }
  };

  const authenticate = (data) => {
    const user = {
      isAuthenticated: true,
      displayName: `${data.first_name} ${data.last_name}`,
      firstName: data.first_name,
      lastName: data.last_name,
      username: data.username,
      roles: data.roles,
      mobile: data.mobile,
    };
    setUser(user);
    storage?.set("token", data.token);
    storage?.set("user", user);
    storage?.set("refreshToken", data.refresh_token);
    let _route = "/dashboard";

    if (data.roles.includes("STUDENT")) {
      _route = "/pending-exams";
    }
    if (data.roles.includes("CUSTOMER")) {
      _route = "/exam-analysis";
    }

    history.push(_route);
  };

  useEffect(() => {
    signIn();
  }, []);

  return (
    <Paper
      elevation={0}
      style={{ display: "flex", justifyContent: "center", marginTop: "24px" }}
    >
      {loading && <CircularProgress />}
    </Paper>
  );
};

export default SignInRedirect;

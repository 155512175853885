import styled from "@emotion/styled";
import React from "react";

function DividerH({ margin, color }) {
  return <Wrapper margin={margin} color={color}></Wrapper>;
}

export default DividerH;

const Wrapper = styled.div`
  height: 2px;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  background: ${(props) => (props.color ? props.color : "rgba(237, 237, 237, 1)")};
`;

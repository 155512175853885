import styled from "@emotion/styled";
import React from "react";
import { H6 } from "../../../../layouts/TextStyles";
import SummaryItem from "./SummaryItem";

const Data = [
  //  { title: "Summary"},
  { key: "Имя", value: "Qairat Almaty" },
  { key: "Экзамен", value: "ЕНТ" },
  { key: "Дата покупки", value: "16 March, 12:43" },
  { key: "Дата завершения", value: "17 March, 15:02" },
  { key: "Длительность", value: "3h 23min" },
];

function Summary() {
  return (
    <Wrapper>
      <H6 style={{ fontWeight: 400 }}>Общая информация</H6>

      {Data.map((d) => (
        <SummaryItem item={d.key} value={d.value} />
      ))}
    </Wrapper>
  );
}

export default Summary;

const Wrapper = styled.div`
  grid-area: summary;
  background: ${(props) => (props.bg ? props.bg : "#fff")};
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  /* height: 110px; */
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

// const Container = styled.div`
// display: ;
// `

import styled from "@emotion/styled";
import React from "react";
import { Body1, H4 } from "../../../../layouts/TextStyles";

function Card({ bg, color, title, score }) {
  return (
    <Wrapper bg={bg} color={color}>
      <Body1 style={{ color: color, fontWeight: 600 }}>{title}</Body1>
      <H4 style={{ color: color }}>{score}</H4>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: ${(props) => (props.bg ? props.bg : "#cdcdcd")};
  /* background: ${(props) => (props.bg ? props.bg : "#cdcdcd")}; */
  border-radius: 16px;
  padding: 24px;
  width: 100%;

  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${(props) => (props.color ? props.color : "#1d1e1c")};
  @media screen and (max-width: 500px) {
    /* height: 60px; */
    flex-direction: column;
  }
`;

export default Card;

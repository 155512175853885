import styled from "@emotion/styled";
import React from "react";

function Success() {
  return (
    <Wrapper>
      <CheckWrapper>
        <CheckMark />
      </CheckWrapper>
      <ScoreText>
        Вы успешно завершили <br /> экзамен
      </ScoreText>
      <ScoreP>Анализ будет доступен после объявления результатов</ScoreP>
    </Wrapper>
  );
}

export default Success;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 120px;
  align-items: center;
`;

const CheckMark = styled.div`
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 30px;

  :before {
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    width: 6px;
    border-radius: 16px;
    background-color: #66ad2b;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
  }

  :after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 6px;
    width: 100%;
    background-color: #66ad2b;
    border-radius: 16px 16px 16px 12px;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
  }
`;

const CheckWrapper = styled.div`
  @keyframes TransitioningBackground {
    0% {
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  position: relative;
  background: #fff;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0;
  margin: auto;

  transform: scale(0.7);
  animation: TransitioningBackground 0.4s ease-out 0.1s 1 normal forwards;
`;

const ScoreText = styled.h4`
  color: #1d1e1c;
  font-weight: 600;
  font-size: 22px;
  margin: 32px;
  text-align: center;
  line-height: 140%;
`;

const ScoreP = styled.p`
  color: #e0e0e0;
  font-size: 16px;
  line-height: 130%;
  padding-top: 16px;
  font-weight: 400;
  width: 240px;
  text-align: center;
`;

import { useEffect } from "react";

const useOnClickOutside = (ref, tooltipRef, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        tooltipRef.current
      ) {
        return;
      }

      //   ref.current &&
      //   !ref.current.contains(event.target) &&
      //   !tooltipRef.current.contains(event.target)
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;

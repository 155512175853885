import styled from "@emotion/styled";
import React from "react";
import { Body1 } from "../../../../layouts/TextStyles";

function OverviewItem({ subject, total }) {
  const foo = new Array(total).fill();

  return (
    <Wrapper>
      <Body1
        style={{
          //   color: "#637381",
          color: "#000",
          opacity: 0.7,
          minWidth: 280,
          position: "sticky",
        }}
      >
        {subject}
      </Body1>
      <Container>
        {foo.map((a, i) => (
          <Box>{i + 1}</Box>
        ))}
      </Container>
    </Wrapper>
  );
}

export default OverviewItem;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  /* overflow: hidden; */
  /* justify-content: space-between; */
  gap: 10px;
`;
const Box = styled.div`
  height: 40px;
  width: 40px;
  background: #eff2f8;
  color: #a2a6b8;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  gap: 8px;
  padding-right: 20px;
`;
